<template>
  <div class="bryntum-calendar-wrapper">
    <div class="dates-range">
      <div class="dates-range-left">
        <base-button class="colored" @click="goTo(-30)">
          <i class="far fa-angle-double-left"></i> 30
        </base-button>
        <base-button class="outline" @click="goTo(-7)">
          <i class="far fa-angle-double-left"></i> 7
        </base-button>
      </div>
      <div class="dates-range-center">
        <base-button class="outline" @click="today">
          <i class="far fa-angle-double-right"></i>
          {{ $t("COMMON.TODAY") }}
          <i class="far fa-angle-double-left"></i>
        </base-button>
      </div>
      <div class="dates-range-right">
        <base-button class="outline" @click="goTo(7)">
          7 <i class="far fa-angle-double-right"></i>
        </base-button>
        <base-button class="colored" @click="goTo(30)">
          30 <i class="far fa-angle-double-right"></i>
        </base-button>
      </div>
    </div>
    <bryntum-scheduler
      v-bind="schedulerConfig"
      :resources="resources"
      event-style="rounded"
      :start-date="startDate"
      :end-date="endDate"
      :resourceTimeRanges="resourceTimeRange"
      :resourceTimeRangesFeature="true"
      :timeRangesFeature="true"
      :timeRanges="timeRanges"
    />
  </div>
</template>

<script>
import { BryntumScheduler } from "@bryntum/scheduler-vue";
import { StringHelper } from "@bryntum/scheduler";
import { DateHelper } from "@bryntum/scheduler";
export default {
  name: "pricing-calendar-component",

  components: { BryntumScheduler },

  props: {
    pricingRules: {
      type: Array,
      required: true,
    },
    startDateValue: {
      type: [Date, String],
      required: true,
    },
    endDateValue: {
      type: [Date, String],
      required: true,
    },
  },

  data() {
    const startDate = this.startDateValue;
    const endDate = this.endDateValue;
    return {
      key: 666,
      startDate,
      endDate,
    };
  },

  watch: {
    startDate() {
      this.key++;
    },
    endDate() {
      this.key++;
    },
    startDateValue(value) {
      this.startDate = value;
    },
    endDateValue(value) {
      this.endDate = value;
    },
  },
  computed: {
    resources: function () {
      const resourcesList = [];
      this.pricingRules.forEach((pricing) => {
        resourcesList.push({
          id: pricing.id,
          name: `${pricing.name} ( ${this.$formatCurrency(pricing.value)} ) `,
        });

        pricing.derived.forEach((derived) => {
          resourcesList.push({
            id: derived.id,
            name: `${derived.name} (${derived.variation} ${
          derived.pricing_type === "percent" ? derived.value + "%" : this.$formatCurrency(derived.value)
        })`,
          });
        });
      });

      return resourcesList;
    },

    // events: function () {},
    timeRanges: function () {
      const timeRanges = [];

      if (this.startDate < new Date()) {
        timeRanges.push({
          id: 1,
          cls: "shaded",
          startDate: DateHelper.clearTime(new Date(this.startDate)),
          endDate: new Date(),
        });
      }

      return timeRanges;
    },
    // resourceTimeRange: function () {
    //   return this.pricingRules.map((pricing) => ({
    //     id: pricing.id,
    //     resourceId: pricing.id,
    //     startDate: DateHelper.clearTime(new Date(pricing.created_at)),
    //     duration: 1,
    //     name: pricing.value,
    //     recurrenceRule: "FREQ=DAILY",
    //   }));
    // },

    // resourceTimeRange: function () {
    //   const timeRanges = [];

    //   this.pricingRules.forEach((pricing) => {
    //     timeRanges.push({
    //       id: pricing.id,
    //       resourceId: pricing.id,
    //       startDate: DateHelper.clearTime(new Date(pricing.created_at)),
    //       duration: 1,
    //       name: pricing.value,
    //       recurrenceRule: "FREQ=DAILY",
    //     });

    //     if (pricing.derived) {
    //       pricing.derived.forEach((derived) => {
    //         //  if (derived.seasonal && derived.start_date && derived.end_date) {
    //         // const startDate = new Date(derived.start_date);
    //         // const endDate = new Date(derived.end_date);

    //         // for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {

    //         //   timeRanges.push({
    //         //     id: derived.id,
    //         //     resourceId: pricing.id,
    //         //     startDate: new Date(date),
    //         //     duration: 1,
    //         //     name: `.. ${derived.value}`,
    //         //     recurrenceRule: "FREQ=DAILY",
    //         //   });
    //         // }
    //         //}else{

    //         timeRanges.push({
    //           id: derived.id,
    //           resourceId: derived.id,
    //           startDate: DateHelper.clearTime(new Date(derived.created_at)),
    //           duration: 1,
    //           name: `${derived.variation} ${derived.value}`,
    //           timeRangeColor: "purple",
    //           recurrenceRule: "FREQ=DAILY",
    //         });

    //         //  }
    //       });
    //     }
    //   });

    //   return timeRanges;
    // },

    resourceTimeRange: function () {
      const timeRanges = [];
      this.pricingRules.forEach((pricing) => {
        timeRanges.push({
          id: pricing.id,
          resourceId: pricing.id,
          startDate: DateHelper.clearTime(new Date(pricing.created_at)),
          duration: 1,
          name: `${this.$formatCurrency(pricing.value)}`,
          recurrenceRule: "FREQ=DAILY",
        });

        if (pricing.derived) {
          pricing.derived.forEach((derived) => {
            let derivedValue = pricing.value;

            if (derived.pricing_type === "percent") {
              if (derived.variation === "+") {
                derivedValue += (pricing.value * derived.value) / 100;
              } else if (derived.variation === "-") {
                derivedValue -= (pricing.value * derived.value) / 100;
              }
            } else {
              if (derived.variation === "+") {
                derivedValue += derived.value;
              } else if (derived.variation === "-") {
                derivedValue -= derived.value;
              }
            }
            timeRanges.push({
              id: derived.id,
              resourceId: derived.id,
              startDate: DateHelper.clearTime(new Date(derived.created_at)),
              duration: 1,
              name: `${this.$formatCurrency(derivedValue)}`,
              timeRangeColor: "purple",
              recurrenceRule: "FREQ=DAILY",
            });
          });
        }
      });

      return timeRanges;
    },

    schedulerConfig: function () {
      return {
        features: {
          resourceTimeRanges: true,
        },
        viewPreset: "dayAndMonth",
        rowHeight: 60,
        barMargin: 5,
        tickSize: 50,

        columns: [
          {
            text: this.$t("COMMON.PRICING_RULES"),
            field: "name",
            width: 260,

            htmlEncode: false,
            renderer: ({ value, record }) =>
              StringHelper.xss`<div class=""> <span>${StringHelper.encodeHtml(
                value
              )}`,
          },
        ],

        columnLines: false,

        eventRenderer: ({ eventRecord }) => {
          return StringHelper.xss`<span class=""><span class="user"></span><small>${eventRecord.personCapacity}</small></span>`;
        },

        resourceTimeRangeRenderer({ resourceTimeRangeRecord, renderData }) {
          renderData.style = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          };

          return {
            tag: "div",
            html: `<strong>${resourceTimeRangeRecord.name}</strong>`,
          };
        },
      };
    },
  },
  methods: {
    goTo(number) {
      this.startDate = DateHelper.add(this.startDate, number, "days");
      this.endDate = DateHelper.add(this.endDate, number, "days");
    },

    today() {
      const startDate = DateHelper.add(new Date(), -5, "days");
      const endDate = DateHelper.add(new Date(), 30, "days");

      if (this.startDate > startDate) {
        this.startDate = startDate;
        this.$nextTick(function () {
          this.endDate = endDate;
        });
      } else {
        this.endDate = endDate;
        this.$nextTick(function () {
          this.startDate = startDate;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@bryntum/scheduler/scheduler.stockholm.css";

.clean,
.dirty {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 9999px;
}
.b-timeline-subgrid .shaded {
  border-inline-end: 2px solid red;
  background-color: transparent;
}
.b-sch-range.shaded {
  background-color: transparent;
}
.clean {
  background-color: #e81212;
}

.dirty {
  background-color: #72e812;
}
</style>
