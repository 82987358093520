<template>
  <div>
    <div class="page-wrapper-table booking-table">
      <div class="page-wrapper-table-header">
        <h2>{{ $t("PRICING_RULES.SELECT_A_TIME_INTERVAL") }}</h2>

        <div class="booking-search-form">
          <base-input class="search-dates">
            <el-date-picker
              v-model="selectedDateRange"
              type="daterange"
              start-placeholder="Date de début"
              end-placeholder="Date de fin"
              format="dd MMM yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </base-input>
        </div>
      </div>
      <div
        :class="
          'page-wrapper-table-body ' +
          (filterReduced ? 'page-wrapper-table-body--folded' : '')
        "
      >
        <pricing-calendar-component
          :startDateValue="startDate"
          :endDateValue="endDate"
          :pricingRules="pricingRules"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import fr from "element-ui/lib/locale/lang/fr";
import locale from "element-ui/lib/locale";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Row,
  Select,
  Slider,
  Table,
  TableColumn,
  Tooltip,
  Collapse,
  CollapseItem,
  Switch,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
// import { BasePagination } from "@/components";
import PricingCalendarComponent from "@/components/PricingCalendarComponent.vue";
// import SpotTypeSelector from "@/components/SpotTypeSelector.vue";

locale.use(fr);

export default {
  name: "calendar-table",

  components: {
    // BasePagination,
    PricingCalendarComponent,
    // SpotTypeSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Slider.name]: Slider,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Input.name]: Input,
    [InputNumber.name]: InputNumber,
    [Row.name]: Row,
    [Col.name]: Col,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Switch.name]: Switch,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    const date = new Date();
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let selectedDateRange = [startDate, endDate];

    return {
      query: null,

      selectedDateRange: selectedDateRange,
      sort: "-created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      pricingRules: [],
      startDate,
      endDate,
      loading: true,
      filterReduced: false,
      filters: {
        availability: null,
        spotCategories: [],
      },
      searchCriteria: {
        search: null,
        dateRange: [],
        adults_capacity: null,
        children_capacity: null,
        pets_capacity: null,
      },
      capacityFilterPopOverDisplayed: false,
      selectedOrganization: null,
      selectedLocations: null,

      calendarFilter: {
        date: "today",
        status: null,
        search: "",
        pricingRule: null,
      },
    };
  },

  created() {
    this.getList();
  },

  watch: {
    selectedDateRange: function () {
      this.startDate = this.selectedDateRange[0];
      this.endDate = this.selectedDateRange[1];
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 500),

    async getList() {
      try {
        this.loading = true;
        let params = {
          filter: {
            pricing_rule_type: "standard",
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,derived,spotTypes",
        };

        if (this.activeTab == this.CALENDAR_TAB) {
          if (this.calendarFilter.search) {
            params.filter.search = this.calendarFilter.search;
          }

          if (this.calendarFilter.status) {
            params.filter.status = this.calendarFilter.status;
          }
        } else {
          if (this.searchCriteria.search) {
            params.filter.search = this.searchCriteria.search;
          }

          if (this.searchCriteria.dateRange) {
            params.filter.availability_date_range =
              this.searchCriteria.dateRange;
          }

          if (this.searchCriteria.adults_capacity) {
            params.filter.adults_capacity = this.searchCriteria.adults_capacity;
          }
        }

        if (this.searchCriteria.children_capacity) {
          params.filter.children_capacity =
            this.searchCriteria.children_capacity;
        }

        if (this.searchCriteria.pets_capacity) {
          params.filter.pets_capacity = this.searchCriteria.pets_capacity;
        }

        const organisationFilter =
          this.filterOrganization ?? this.selectedOrganization;

        if (organisationFilter) {
          params = {
            ...params,
            filter: { ...params.filter, organization: organisationFilter },
          };
        }

        await this.$store.dispatch("pricingRules/list", params);
        this.pricingRules = this.$store.getters["pricingRules/list"];
        this.total = this.$store.getters["pricingRules/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async fetchPricingRules() {
      try {
        this.processing = true;
        let params = {
          page: {
            number: 1,
            size: 999,
          },
          include: "organization,derived,spotTypes",
        };
        await this.$store.dispatch("pricingRules/list", params);
        this.pricingRules = this.$store.getters["pricingRules/list"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>

<style>
.gap-16px {
  gap: 16px;
}
</style>
